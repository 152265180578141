import {  qspost } from '@/config/axios';

//获取列表数据
export const getList = data => qspost("request?", data ,"getGameList");

//获取游戏分类数据
export const getGameType = data => qspost("request?", data ,"getGameType");

//禁用、启用游戏
export const useGame = data => qspost("request?", data ,"useGame");

//开启、关闭热门
export const setHot = data => qspost("request?", data ,"setHot");

//编辑游戏数据
export const updateGame = data => qspost("request?", data ,"updateGame");